import React from "react";

const Home = () => {
  return (
    <div className="container m-1">
        <h2 className="text-center text-danger">Teen Patti 11</h2>
      <div className="row my-2">
        <div className="col-12">
          <div
            id="carouselExampleControls"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <a rel="noreferrer" href="http://share.rummyroom.in?invite_code=226479" target="_blank">
                    <img src="assets/1.jpg" className="d-block w-100 text-center" alt="Teen Patti 11 Screenshot 1" />
                </a>
              </div>
              <div className="carousel-item">
                <a rel="noreferrer" href="http://share.rummyroom.in?invite_code=226479" target="_blank">
                <img src="assets/2.jpg" className="d-block w-100 mx-auto" alt="Teen Patti 11 Screenshot 2" />
                </a>
              </div>
              <div className="carousel-item">
                <a rel="noreferrer" href="http://share.rummyroom.in?invite_code=226479" target="_blank">
                    <img src="assets/3.jpg" className="d-block w-100 mx-auto" alt="Teen Patti 11 Screenshot 3" />
                </a>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-2 col-sm-12 col-xs-12">
            <a className="btn btn-danger" 
            rel="noreferrer" 
            href="http://share.rummyroom.in?invite_code=226479" 
            target="_blank" style={{width: "100%"}}>
                Download
                </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
